<template>
  <div class="route">
    <Spinner />
    <FilterRoutes />
    <SubHeaderBlock />
    <div class="container">
      <TableBlock class="route__table" />
    </div>
  </div>
</template>

<script>
import Spinner from "../../components/spinner/index.vue";
export default {
  components: {
    SubHeaderBlock: () => ({
      component: import("./components/subHeader"),
    }),
    FilterRoutes: () => ({
      component: import("../../components/filter/filterRoutes.vue"),
    }),
    TableBlock: () => ({
      component: import("./components/table"),
    }),
    Spinner,
  },
  mounted() {
    this.$store.commit("setOrderById", null);
  },
};
</script>

<style scoped>
.route__table {
  margin-top: 20px;
}
.spinner {
  top: 50%;
}
</style>
